import 'nprogress/nprogress.css';
import Router from 'next/router';
import NProgress from 'nprogress';

if (typeof window !== 'undefined') {
  NProgress.configure({showSpinner: true});

  Router.events.on('routeChangeStart', () => {
    NProgress.start();
  });

  Router.events.on('routeChangeComplete', () => {
    NProgress.done();
  });

  Router.events.on('routeChangeError', () => {
    NProgress.done();
  });
}
