import cx from 'classnames';
import Router from 'next/router';
import {v4 as uuid} from 'uuid';
import type {ReactNode} from 'react';
import {Component, useContext} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import type {Alert} from '@/contexts/alerts-context';
import {AlertsContext, AlertType} from '@/contexts/alerts-context';

interface Props {
  readonly children: ReactNode;
}

interface State {
  readonly alerts: Alert[];
}

class AlertsProvider extends Component<Props, State> {
  public readonly state: Readonly<State> = {alerts: []};

  public componentDidMount(): void {
    Router.events.on('routeChangeComplete', this.clearAlerts);
    Router.events.on('routeChangeError', this.clearAlerts);
  }

  public componentWillUnmount(): void {
    Router.events.off('routeChangeComplete', this.clearAlerts);
    Router.events.off('routeChangeError', this.clearAlerts);
  }

  public render(): JSX.Element {
    const {alerts} = this.state;

    return (
      <AlertsContext.Provider
        value={{
          alerts,
          info: this.info,
          success: this.success,
          warning: this.warning,
          error: this.error,
        }}
      >
        {this.props.children}
        <div className="fixed left-4 bottom-2 z-50 max-w-full md:max-w-xs">
          <motion.ul>
            <AnimatePresence>
              {alerts.map((a) => (
                <motion.li
                  key={a.id}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={{hidden: {x: '-100%', opacity: 0}, visible: {x: 0, opacity: 1}}}
                  className={cx('px-5 py-3 text-sm rounded-lg shadow-md mb-2 cursor-pointer text-white', {
                    'bg-blue-600': a.type === AlertType.Info,
                    'bg-green-500': a.type === AlertType.Success,
                    'bg-yellow-600': a.type === AlertType.Warning,
                    'bg-red-500': a.type === AlertType.Error,
                  })}
                  onClick={this.remove(a)}
                >
                  {a.message}
                </motion.li>
              ))}
            </AnimatePresence>
          </motion.ul>
        </div>
      </AlertsContext.Provider>
    );
  }

  /** @internal */
  private alert = (alert: Alert, timeout: number) => {
    const {alerts} = this.state;
    this.setState({alerts: [alert, ...alerts]});
    setTimeout(this.remove(alert), timeout);
  };

  /** @internal */
  private info = (message: string, timeout: number = 5000) => {
    this.alert(
      {
        id: uuid(),
        type: AlertType.Info,
        message,
      },
      timeout,
    );
  };

  /** @internal */
  private success = (message: string, timeout: number = 5000) => {
    this.alert(
      {
        id: uuid(),
        type: AlertType.Success,
        message,
      },
      timeout,
    );
  };

  /** @internal */
  private warning = (message: string, timeout: number = 5000) => {
    this.alert(
      {
        id: uuid(),
        type: AlertType.Warning,
        message,
      },
      timeout,
    );
  };

  /** @internal */
  private error = (message: string, timeout: number = 5000) => {
    this.alert(
      {
        id: uuid(),
        type: AlertType.Error,
        message,
      },
      timeout,
    );
  };

  /** @internal */
  private remove = (alert: Alert) => () => {
    const {alerts} = this.state;
    const copy = [...alerts];
    copy.splice(alerts.indexOf(alert), 1);
    this.setState({alerts: copy});
  };

  /** @internal */
  private clearAlerts = () => {
    this.setState({alerts: []});
  };
}

export default AlertsProvider;

export const useAlerts = () => useContext(AlertsContext);
