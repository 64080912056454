import 'tailwindcss/tailwind.css';
import '@/styles/main.scss';
import '@/utils/nprogress';
import ar from '@/assets/translations/ar.json';
import en from '@/assets/translations/en.json';
import AlertsProvider from '@/components/alerts';
import {IntlProvider} from 'react-intl';
import {AuthProvider} from '@/contexts/auth-context';
import type {FC} from 'react';
import type {AppProps} from 'next/app';

const translations = {en, ar};

const App: FC<AppProps> = ({Component, pageProps, router}) => (
  <IntlProvider
    messages={translations[(router.locale || 'en') as keyof typeof translations] as any}
    locale={router.locale || 'en'}
  >
    <AuthProvider>
      <AlertsProvider>
        <Component {...pageProps} />
      </AlertsProvider>
    </AuthProvider>
  </IntlProvider>
);

export default App;
